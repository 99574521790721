<template>
  <router-view></router-view>
</template>
<script>
import { financing_history, subjects } from "@/api/CapitalCalculation.js";
import * as sesstion from "@/store/localStorage";
import { checkpoint, logActivity } from "@/api.js";
export default {
  beforeCreate() {},
  created() {
    this.$VM[this.$route.name] = this;

    // this.$router.push({ name: "DataEntry" });
    // this.$router.push({ name: "CalculationResults" });
  },
  methods: {
    search(search_data) {
      const param = {
        ...search_data,
        menu_id: 5,
        industry_range: 0,
        industry_number: search_data.industry_code,
        industry_type: search_data.industry_name,
        parsing_time: search_data.year,
      };
      checkpoint(param).then(({ data }) => {
        if (data.Status != 1) return this.$message.warning(data.Message);

        const company = sesstion.getLocalData("company");
        financing_history({ company_code: company.company_code }).then((res) => {
          if (res.status == 200) {
            // console.log(res.data)
            this.$store.commit("setCapitalCalculation", res.data);
            this.$store.commit("setDataEntry", res.data.subjects);
            this.$router.push({
              name: "CalculationResults",
              query: {
                status: 1,
              },
            });
          } else {
            this.$router.push({
              name: "DataEntry",
            });
          }
        });

        logActivity(param);
      });
    },
  },
};
</script>
