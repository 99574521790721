import service from "@/common/request.js";

/**
 * financing
 * @param {year} string
 * @param {report_type} string
 * @param {company_code} string
 * @param {unit}
 */
export const financing_history = (param) =>
  service.post("/financing-calc/financing/history/", param);

/**
 * 获取科目数据
 * @param {year} string
 * @param {report_type} string
 * @param {company_code} string
 * @param {unit}

 */
export const subjects = (param) =>
  service.post("/financing-calc/financing/subjects/ ", param);


/**
 * 计算融资需求
unit 单位， one 元， ten-thousand 万元
year 预测年份 adj_factor 调整系数，没有填 null
annual_growth_operating_receipt 预计销售收入年增长率，没有填 null
cur_liquidity_loans 现有流动资金贷款
other_working_capital 其他渠道提供的运营资金
subjects 科目数据， /financing-calc/financing/subjects/ 格式与接口返回的相同

 */
export const calculate = (param) => {
  Object.keys(param).map((key) => {
    if (param[key] == null) param[key] = "";
  });
  return service.post("/financing-calc/financing/calculate/", param);
};


